import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar5'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/ensino-fundamental-I-e-II 1.png'
import Ellipse from '../images/new/icons/ellipse2.svg'
import Bird from '../images/new/icons/bird.svg'
import Cloud from '../images/new/icons/cloud.svg'
import Button from '../components/StartNowBtn'
import { Link } from 'gatsby'

import { 
  section2,
  HeaderBanner2,
  container,
  content3,
  box1,
  box2,
  desktop
} from '../components/styles/Base.styles'

const EducacaoInfantilI = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Ensino Fundamental | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner2} style={{ background: '#FFD404' }}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section2, content3]} style={{ background: '#FFD404' }}>
      <div css={container}>
        <h1>Ensino Fundamental</h1>
        <p>A Kingdom School é uma escola que surge para romper barreiras, por meio de um projeto pedagógico estruturado de forma singular que permita aos alunos viverem uma experiência significativa no seu processo de formação como ser humano. Acreditamos que a escola ultrapassa o conceito de centro coletivo de ensino para um local sensível ao outro, as individualidades e as potencialidades de cada um.</p>
        <p>Nosso objetivo é utilizar as melhores estratégias e construir um caminho de escolhas para se chegar ao aluno, ao professor e às famílias. Essa base será responsável pela experiência que proporcionaremos aos alunos Kingdom, para que vivam e a levem ao longo da vida pelas trilhas que seguirem a partir dela.</p>
        <p><span>Pensando nisso, a Kingdom School conecta muitos conceitos em um mesmo ambiente!</span></p>
        <Link to='/matriculas-abertas'><Button>MATRICULE-SE AGORA</Button></Link>
        <div style={{ marginBottom: 72 }} />
        <h2>Princípios Pedagógicos</h2>
        <p>O projeto Educacional da Kingdom School, etapa do Ensino Fundamental, promove a continuidade do trabalho que se inicia na Kingdom Kids, na etapa da Educação Infantil. Os ciclos se baseiam nos nossos três pilares: estimulação, segurança e afeto. Para formar a base da estimulação, consideramos alguns importantes pilares desse processo e na busca pelos métodos mais compatíveis com nossa filosofia.</p>
        <p>Utilizamos:</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div css={box1}>
            <ul>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Método fônico de alfabetização;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Método Singapura para a matemática;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Inglês – Programa de Fluência em Inglês –Beyond;</p>
              </li>
              <li>
                <img src={Ellipse} alt='' style={{ width: 'auto' }} />
                <p>Salas de aula ambiente.</p>
              </li>
            </ul>
          </div>
          <div css={desktop}><img src={Cloud} style={{ marginTop: 0, marginLeft: 120 }} /></div>
        </div>
        <h2 style={{ marginTop: 48 }}>Objetivos Gerais do Fundamental</h2>
        <p>A etapa do Ensino Fundamental, reúne uma série de peculiaridades no que se refere ao desenvolvimento da criança. Ao longo dessa etapa, a criança permeará por diversas fases. Por isso, buscamos potencializar o desenvolvimento mental, físico, intelectual e emocional do aluno e conquistar importantes hábitos no que se refere a autonomia pessoal diante dos desafios do dia a dia, como organizar e ser responsável pelo seu material, por gerenciar o seu tempo e por resolver as tarefas, lidar com desafios emocionais e comportamentais e ser capaz de se integrar com o outro de forma equilibrada e saudável, na qual saiba ouvir, falar e respeitar.</p>
        <p>Ao finalizar o Ensino Fundamental I, como conquista desse processo, o aluno terá desenvolvido a capacidade de:</p>
        <div style={{ textAlign: 'right' }} ><img src={Bird} style={{ marginTop: 0 }} /></div>
        <div css={box2}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Aprender a ser – trabalhar a Inteligência Intrapessoal, o autoconhecimento.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Aprender a conviver – desenvolver a Inteligência Interpessoal, saber respeitar e conviver com outros indivíduos, respeitando suas particularidades, temperamentos e histórias de vida. Ser capaz de trabalhar em equipe. Se descobrir em um mundo em que a tecnologia, o mundo digital, Inter Artificial se faz mais presente a cada instante.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Aprender a conhecer – é a capacidade de aprender e de ensinar, por meio da personificação dessa aprendizagem. Aprender a aprender é o conhecimento que se adquire.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Aprender a fazer – a capacidade de criar, por meio de ideias e de ações práticas, ao colocar a mão na massa, a fazer. Utilizar os conhecimentos prévios para solução de problemas.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default EducacaoInfantilI
